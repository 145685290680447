import React, { useState } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import {Channel} from "../channel";


export const VideoJS = (props) => {
    const videoRef = React.useRef(null);
    const playerRef = React.useRef(null);
    const {options, onReady} = props;

    React.useEffect(() => {

        // Make sure Video.js player is only initialized once
        if (!playerRef.current) {
            // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
            const videoElement = document.createElement("video-js");

            videoElement.classList.add('vjs-big-play-centered');
            videoRef.current.appendChild(videoElement);

            const player = playerRef.current = videojs(videoElement, options, () => {
                videojs.log('player is ready');
                onReady && onReady(player);
            });

            // You could update an existing player in the `else` block here
            // on prop change, for example:
        } else {
            const player = playerRef.current;

            player.autoplay(options.autoplay);
            player.src(options.sources);
        }
    }, [options, videoRef]);

    // Dispose the Video.js player when the functional component unmounts
    React.useEffect(() => {
        const player = playerRef.current;

        return () => {
            if (player && !player.isDisposed()) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    return (
        <div data-vjs-player>
            <div ref={videoRef} />
        </div>
    );
}


export const HLS = ({ icon = '', title, hls = [], items, setItem }) => {

    const videoJsOptions = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        playsinline: true,
        nativeTextTracks: false,
        sources: [{
            src: hls[0],
            type: 'application/x-mpegURL'
        }]
    };

    return (
        <div className={`hls--player`}>
            <div className={`hls--header`}>
                <div className="lwrapper">
                    <a href={"#"} onClick={(e) => {
                        e.preventDefault();
                        document.getElementById('modal').classList.add('visible');
                    }}>
                        <img src={"/img/channels.svg"} alt={`channels`} />
                    </a>
                    <div className={`channel`}>
                        <div className={`image`} style={icon ? {backgroundImage: `url(${icon})`} : {}}/>
                        <div className="title">{title}</div>
                    </div>
                </div>
            </div>
            <div className={`hls--content`}>
                <VideoJS options={videoJsOptions} />
            </div>
            <div id="modal" className={`channels--modal`}>
                <div className={`modal--header`}>
                    <a href={"#"} onClick={(e) => {
                        e.preventDefault();
                        document.getElementById('modal').classList.remove('visible');
                    }}><img src={"/img/close.svg"} alt={`close`} /></a>
                    <div className={`title`}>Channels list</div>
                </div>
                <div className="modal--content">
                    {items && items.map(i => {
                        return <Channel key={`item_${i.title}`} title={i.title} icon={i.icon || ""} onClick={() => {
                            document.getElementById('modal').classList.remove('visible');
                            setItem(i);
                        }}/>;
                    })}
                </div>
            </div>
        </div>
    );
};