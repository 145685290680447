import React, { useEffect, useState } from 'react';
import { API_URL } from './config';
import "./sass/index.scss"
import {Channel, HLS} from "./components";

export const App = () => {
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState(false);
    const [item, setItem] = useState(false);

    useEffect(() => {
        try {
            fetch(API_URL).then(res => res.json()).then(res => {
                // console.log(`response`, res);
                setLoading(false);
                setItems(res || []);
            }).catch(e => {
                console.error(`[fetch.failed]: `, e)
            });
        } catch(e) {
            console.error(`[failed]: `, e);
        }
    }, []);

    return (
        <div className={`layout`}>
            {item && <HLS  {...item } setItem={setItem} items={items || []}/>}
            <div className={`wrapper${loading ? ` loading` : ''}${item !== false ? ` hidden` : ''}`}>
                {items && items.map(i => {
                    return <Channel title={i.title} icon={i.icon || ""} onClick={() => {
                        setItem(i);
                    }}/>;
                })}
            </div>
        </div>
    );
}